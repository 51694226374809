// JS
import Splide from '@splidejs/splide';
import $ from 'jquery';
// import './app';


// *** Flickity library *** //
// JS
import Flickity from './flickity/flickity.pkgd.min';

// CSS
import '../scss/homepage.scss';
import '../scss/flickity/flickity.scss';
import '../scss/banner-slider.scss';  // banner slider
import '../scss/product-slider.scss'; // odporucane slider

var optionFlickityBanner = {
  cellAlign: 'left',
  contain: true,
  imagesLoaded: true,
  autoPlay: 3000,
  wrapAround: true,
  pageDots: false,
};
var optionFlickityBannerMobile = {
  cellAlign: 'left',
  contain: true,
  imagesLoaded: true,
  autoPlay: 3000,
  wrapAround: true,
  pageDots: false,
};
var optionFlickityNovinkySlider = {
  cellAlign: 'center',
  contain: true,
  imagesLoaded: true,
  autoPlay: false,
  wrapAround: true,
  pageDots: false,
};
var optionFlickityProductSlider = {
  cellAlign: 'left',
  contain: true,
  imagesLoaded: true,
  autoPlay: false,
  wrapAround: true,
  pageDots: true,
  groupCells: true
};
var optionFlickityReferences = {
  cellAlign: 'center',
  contain: true,
  imagesLoaded: true,
  autoPlay: 3000,
  wrapAround: true,
  pageDots: false,
};

const banner = new Flickity('.banner-slider-content', optionFlickityBanner);
const bannerMobile = new Flickity('.banner-slider-mobile-content', optionFlickityBannerMobile);
const novinkySlider = new Flickity('.novinky-slider-content', optionFlickityNovinkySlider);
const productSlider = new Flickity('.product-slider-content', optionFlickityProductSlider);
const references = new Flickity('.references-content', optionFlickityReferences);

$(window).on('load', function(){
  porovnanie('.product-slider-content .product-card-wrap');
});

$(window).on('resize', function(){
  porovnanie('.product-slider-content .product-card-wrap');
});


function porovnanie(selector) {
  $(selector).css('height','auto');
  var t=0; // the height of the highest element (after the function runs)
  var t_elem;  // the highest element (after the function runs)
  $(selector).each(function () {
    if ( $(this).outerHeight() > t ) {
      t_elem=this;
      t=$(this).outerHeight();
    }
  });
  $(selector).css('height',t +'px');
}
